<template>
    <div class="pageSize" v-if="objData.allBodyAnalysisInModel?.symptomList" style="position: relative;">

        <Head text="健康分析" bgColor="#2baea9"></Head>
        <div class="tree_content">
            <div class="title">
                <div class="introduction">
                    <!-- {{ objData.allBodyAnalysisInModel.symptomList }} -->
                    一棵树生病表现为树叶发黄,问题的根本并不在于树叶本身,而往往在于树干和树根的健康状况出现了问题。人体出现的不适症状，异常指标，疾病就如同发黄的树叶，综合分析与深层的树干<span
                        style="color:#4A9AAF">七大失衡因素</span>和树根<span
                        style="color:#4A9AAF">前置因素、诱发因素、介质因素</span>失调相关,只有找到疾病发病的深层原因,才能针对性的进行平衡和纠偏,取得有效的疾病预防和健康管理。
                </div>
                <div class="introduction"> 所以我们根据您上传的医疗健康数据,将用功能医学思维,以生命之树形式,给您一份身体的说明书。</div>
            </div>
            <div class="tree">
                <div style="padding-top: 120px;position: relative;">
                    <div class="sign">
                        <div class="sing_img">
                            <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240430/BA8376AF99B64F6A8A0ABFCD4D1CB511.png"
                                class="wh100" alt="">
                        </div>
                        <div class="sign_text"> 体征与症状</div>
                        <div class="sing_img">
                            <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240430/BA8376AF99B64F6A8A0ABFCD4D1CB511.png"
                                class="wh100" alt="">
                        </div>
                        <div class="sign_item" :class="`sign_item${index + 1}`"
                            v-for="(item, index) in objData.allBodyAnalysisInModel.symptomList">
                            <template v-if="item.projectColorCode">
                                <div class="sign_item_round" :style="{background:`${item.projectColorCode}`,borderColor: item.projectColorCode,borderStyle:'solid'}">
                                    <div  style="display: flex; align-items: center;justify-content: center;color: #fff;">
                                        {{ item.projectName }}</div>
                                    <!-- <div style="display: flex; align-items: center;justify-content: center;">{{
                                        item.projectLevel
                                    }}</div> -->
                                </div>
                                <div class="sign_item_text" v-if="item.isFactorFunction">(功能)</div>
                            </template>
                            <template v-else>
                                <div class="sign_item_round2">
                                        <div style="display: flex; align-items: center;justify-content: center;">
                                            {{ item.projectName }}</div>
                                        <div class="imgs">
                                            <img class="wh100"
                                                src="https://image.giantgocloud.com/www/ImageMapping/image/20240506/764317A07E3F4039AB18C8F437331E9B.png"
                                                alt="">
                                        </div>
                                    </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="unbalance">
                    <div class="unbalance_title">
                        <div class="unbalance_img">
                            <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240430/BA8376AF99B64F6A8A0ABFCD4D1CB511.png"
                                class="wh100" alt="">
                        </div>
                        <div class="unbalance_text">七大生理失衡</div>
                        <div class="unbalance_img">
                            <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240430/BA8376AF99B64F6A8A0ABFCD4D1CB511.png"
                                class="wh100" alt="">
                        </div>
                    </div>
                    <div class="unbalance_text" v-if="unbalanceColor.length > 0">
                        <span style="padding: 0 10px;" v-for="(item, index) in unbalanceList">{{ item }}</span>
                        <span style="color: #EEC122;padding: 0 10px;" v-for="(item, index) in unbalanceColor">{{ item
                            }}</span>
                    </div>
                </div>
                <div class="factors">
                    <div class="factors_img">
                        <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240430/BA8376AF99B64F6A8A0ABFCD4D1CB511.png"
                            class="wh100" alt="">
                    </div>
                    <div class="factors_text">前置因素/诱发因素/介质因素</div>
                    <div class="factors_img">
                        <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240430/BA8376AF99B64F6A8A0ABFCD4D1CB511.png"
                            class="wh100" alt="">
                    </div>
                </div>
                <div class="correlation">
                    <div class="correlation_item" :class="`correlation_item${index + 1}`"
                        v-for="(item, index) in objData.allBodyAnalysisInModel?.factorList">
                        <div style="position: absolute;background-color: #fff;width: 2px;height: 8px;left: -2px;top: 50%;transform: translateY(-4px);">

                        </div>
                        <div style="position: absolute;background-color: #fff;width: 2px;height: 8px;right: -2px;top: 50%;transform: translateY(-4px);">

                        </div>
                        <span v-if="item.isChecked" style="color: #EEC122;">
                            {{ item.showName }}
                        </span>
                        <span v-else>
                            {{ item.showName }}
                        </span>

                    </div>
                </div>
            </div>
         <!--   <div class="tree_line">
                <img class="wh100"
                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240430/D0E31CC97ABF4A9881295F3DB5D0A728.png"
                    alt="">
            </div> -->
              <!-- <div class="correlation1" style="margin-top: 120px;">
                <div style="background-color: #f0f0ee;border-radius:10px;padding: 20px 20px 20px 150px;position: relative;">
                  <div style="position: absolute;left: 40px;top: -20px;">
                    <img style="width: 100px;" src="https://image.giantgocloud.com/www/ImageMapping/image/20240929/74C72CAF289A41EDAFB0FD946B8CEA72.png" alt="" />
                  </div>
                  您的 <span v-for="(item, index) in objData.allBodyAnalysisInModel?.symptomList">
                      <template
                          v-if="item.projectColorCode && item.projectColorCode != '#5EC692' && item.projectColorCode != '#10854A'">
                          <span :style="{ color: item.projectColorCode }">
                              <template v-if="index != 0" style="color:#000">、</template>{{ item.projectName }}
                          </span>
                      </template>
                  </span>,综合分析与以下<span style="color: #EEC122;"
                      v-for="(item, index) in objData.allBodyAnalysisInModel?.text2">
                      {{ item }}
                      <template v-if="index + 1 != objData.allBodyAnalysisInModel?.text2.length"
                          style="color:#000">、</template>
                  </span>因素相关,而这些失衡的因素又与您的<span style="color: #EEC122;"
                      v-for="(item, index) in objData.allBodyAnalysisInModel?.text3">
                      {{ item }}
                      <template v-if="index + 1 != objData.allBodyAnalysisInModel?.text3.length"
                          style="color:#000">、</template>
                  </span>相关。
                </div>

              </div> -->

        </div>
        <!-- <Bottom /> -->
        <div class="footerNum">6 of 6</div>
    </div>
</template>

<script>
import Head from './head.vue'
import Bottom from './bottom.vue'
import { color } from 'echarts'
export default {
  props: ['objData'],
  data () {
    return {
      dataList: [],
      unbalanceList: [],
      unbalanceColor: [],
      treeRootList: [
        {
          title: '既往史'
        },
        {
          title: '家族史'
        },
        {
          title: '人际关系'
        },
        {
          title: '压力失衡'
        },
        {
          title: '毒素/环境'
        },
        {
          title: '睡眠'
        },
        {
          title: '生活方式'
        },
        {
          title: '饮食'
        }
      ]
    }
  },
  methods: {
    hexToRgb (hex, opt) {
      // Remove "#" symbol from the beginning of the string
      hex = hex.replace('#', '')
      // Convert each pair of characters to decimal values for red, green, and blue
      var red = parseInt(hex[0] + hex[1], 16)
      var green = parseInt(hex[2] + hex[3], 16)
      var blue = parseInt(hex[4] + hex[5], 16)
      return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')'
    }
  },
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValue, oldValue) {
        const sourceList = ['消化吸收屏障失衡', '神经内分泌失衡', '肝脏生物转化失衡', '免疫与炎症失衡', '氧化还原失衡', '循环运输失衡', '代谢失衡']
        const arr = newValue.allBodyAnalysisInModel.unBalanceList.map(item => item.unBalanceTitle)
        sourceList.map(item => {
          if (arr.indexOf(item) > -1) {
            this.unbalanceColor.push(item)
          } else {
            this.unbalanceList.push(item)
          }
        })
        // console.log(' this.unbalanceList', this.unbalanceList)
        // console.log(' this.unbalanceColor', this.unbalanceColor)
        // console.log('newValue1111111', newValue.allBodyAnalysisInModel.unBalanceList)
      }
    }
  },
  components: {
    Head,
    Bottom
  }
}
</script>

<style lang="scss" scoped>
.tree_content {
    .title {
        padding-top: 80px;

        .introduction {
            padding: 0 102px;
            font-weight: 400;
            font-size: 20px;
            color: #474F57;
            line-height: 32px;
        }
    }

    .tree {
        width: 703px;
        height: 751px;
        margin: 0 auto;
        margin-top: 45px;
        background-image: url('https://image.giantgocloud.com/www/ImageMapping/image/20240929/B6116E0D8A6A459DACD8BF7821EE557A.png');
        background-size: cover;
        background-repeat: no-repeat;

        .sign {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 261px;
            height: 115px;
            background: #2baea9;
            border-radius: 50%;
            margin: 0 auto;
            text-align: center;
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-size: 28px;
            color: #FFFFFF;

            .sign_text {
                padding: 0 13px;
            }

            .sing_img {
                width: 22px;
                height: 15px;
            }

            .sign_item {
                .sign_item_round {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 87px;
                    height: 87px;
                    background: #FAE8EA;
                    border-radius: 50%;
                    font-weight: 600;
                    font-size: 16px;
                    // color: #D51931;
                }

                .sign_item_round2 {
                    width: 87px;
                    height: 87px;
                    padding-top: 20px;
                    background: #B0B1B7;
                    border-radius: 50%;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size:16px;
                    color: #FFFFFF;

                    .imgs {
                        margin-left: 34px;
                        margin-top: 6px;
                        width: 18px;
                        height: 18px;
                    }
                }

                .sign_item_text {
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 17px;
                    color: #474F57;
                    padding-top: 10px;
                }
            }

            .sign_item1 {
                position: absolute;
                top: -34px;
                left: -188px;
            }

            .sign_item2 {
                position: absolute;
                top: -110px;
                left: -95px;
            }

            .sign_item3 {
                position: absolute;
                top: -143px;
                left: 19px;
            }

            .sign_item4 {
                position: absolute;
                top: -141px;
                left: 143px;
            }

            .sign_item5 {
                position: absolute;
                top: -116px;
                left: 291px;
            }

            .sign_item6 {
                position: absolute;
                top: -56px;
                left: 376px;
            }

            .sign_item7 {
                position: absolute;
                top: 76px;
                left: 376px;
            }

            .sign_item8 {
                position: absolute;
                top: 137px;
                left: 270px;
            }

            .sign_item9 {
                position: absolute;
                top: 172px;
                left: 160px;
            }

            .sign_item10 {
                position: absolute;
                top: 172px;
                left: 5px;
            }

            .sign_item11 {
                position: absolute;
                top: 137px;
                left: -123px;
            }

            .sign_item12 {
                position: absolute;
                top: 91px;
                left: -222px;
            }
        }

        .unbalance {
            width: 673px;
            height: 128px;
            margin: 0 auto;
            margin-top: 188px;
            background: #2baea9;
            border-radius: 18px;

            .unbalance_title {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 14px;

                .unbalance_text {
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    font-size: 28px;
                    color: #FFFFFF;
                    padding: 0 13px;
                }

                .unbalance_img {
                    flex-shrink: 0;
                    width: 22px;
                    height: 15px;
                }
            }

            .unbalance_text {
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 18px;
                color: #FFFFFF;
                line-height: 36px;
                padding: 0 44px;
            }
        }

        .factors {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 499px;
            height: 60px;
            margin: 0 auto;
            margin-top: 24px;
            background: #2baea9;
            border-radius: 18px;

            .factors_img {
                width: 22px;
                height: 15px;
            }

            .factors_text {
                font-family: Source Han Sans CN;
                font-weight: 500;
                font-size: 26px;
                color: #FFFFFF;
                padding: 0 13px;
            }
        }

        .correlation {
            position: relative;
            display: flex;
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 18px;
            color: #474F57;
            .correlation_item {
              position: relative;
              border: 1px solid #111;
              padding: 4px 6px;
              border-radius: 10px;
              white-space:nowrap;
            }
            .correlation_item1 {
                position: absolute;
                left: 2px;
                top: 65px;
            }

            .correlation_item2 {
                position: absolute;
                left: 74px;
                top: 85px;
            }

            .correlation_item3 {
                position: absolute;
                left: 148px;
                top: 100px;
            }

            .correlation_item4 {
                position: absolute;
                left: 225px;
                top: 135px;
            }

            .correlation_item5 {
                position: absolute;
                left: 323px;
                top: 137px;
            }

            .correlation_item6 {
                position: absolute;
                left: 413px;
                top: 105px;
            }

            .correlation_item7 {
                position: absolute;
                left: 467px;
                top: 111px;
            }

            .correlation_item8 {
                position: absolute;
                left: 575px;
                top: 87px;
            }
        }
    }

    .tree_line {
        width: 985px;
        height: 19px;
        margin: 0 auto;
        margin-top: 83px;
    }

    .correlation1,
    .correlation2 {
        padding: 0 102px;
        font-family: Source Han Sans CN;
        font-weight: 300;
        font-size: 20px;
        color: #474F57;
        line-height: 30px;
    }

    .correlation1 {
        padding-top: 10px;
    }

    .correlation2 {
        padding-top: 30px;
    }
}
.footerNum{
        z-index: 9999999999;
        position: absolute;
        bottom: 60px;
        left: 92%;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 20px;
        color: #000;
    }
</style>
